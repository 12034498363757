#back-button:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 40px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0.5rem;
    padding: 10px 16px 10px 16px;
    font-size: 18px;
    font-style: bold;
    font-weight: bold 1rem;
    cursor: pointer;
}

#buttons-space {
    width: 50%;
    display: flex;
    flex-direction: row;
    padding: 1rem;

    margin: 0;
}

#csv-button {
    padding: 13px;
    width: 200px;
    border: 1px solid #28A745;
    color: #28A745;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 18px;
    padding: 10px 16px 10px 16px;
    font-size: 18px;
    font-style: bold;
    font-weight: bold 1rem;
    white-space: nowrap;
}

#csv-button:hover {
    cursor: pointer;
}
